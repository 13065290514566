import React, { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { AppState } from '../App';
import logo from '../assets/images/movie-logo.png';

const Header = () => {
  const useAppState = useContext(AppState);

  const logOut = () => {
    useAppState.setLogin(false);
    localStorage.removeItem("UIDDI");
  }

  return (
    <div className='bg-black sticky top-0 z-10 text-3xl flex justify-between text-red-500 font-bold p-3 border-b-2 border-gray-500'>
      <Link to={'/'}><img className='site-logo' src={logo} alt="Logo"/></Link>
      {
        useAppState.login ?
          <Link to={'/addnew'}><h1 className='m-2'>
            <Button><AddIcon className='mr-1' color='secondary' /> <span className='text-white'>Add New</span></Button>
            <Button onClick={logOut}><LogoutIcon className='mr-1' sx={{ color: "red" }} /><span className='text-white'>Logout</span></Button>
          </h1></Link>
          :
          <Link to={'/login'}><h1 className='m-2'>
            <Button style={{padding:"0px"}}><span className='text-white bg-green-600 capitalize text-lg p-1'>Login</span></Button>
          </h1></Link>
      }
    </div>
  )
}

export default Header